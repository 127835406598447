<template>
<div data-theme="easyjoey" class="flex flex-col min-h-screen pb-[300px] bg-[#f5f5f5]">
  <global-snackbar />
  <!-- Header -->

  <div class="flex flex-grow min-h-screen">
    <!-- Main Content -->
    <main class="flex-1 transition-all duration-300">
      <NuxtPage/>
    </main>
  </div>
</div>
</template>

<script>
import { computed, onMounted, watch } from 'vue';
import { useEncounters } from '@/composables/use-encounters';
import { useRoute } from 'vue-router';
import { useMedicalRecords } from '@/composables/use-medical-records';
import GlobalSnackbar from '@/components/commons/global-snack';

export default {
  components: {
    GlobalSnackbar,
  },
  setup () {
    const route = useRoute();

    // FORCE REDIRECT IF NOT IN CONSULT PAGE
    onMounted(() => {
      // if (!route.name.startsWith('consult') && !route.name.startsWith('patient-demo')) {
      //   router.push('/404');
      // }
    });

    const { list: listMedicalRecords, listMedicalRecordsHistory } = useMedicalRecords();
    const { encounters, encounter, init: getPatientEncounters, get: getCurrentEncounter } = useEncounters();

    const patientId = computed(() => route.query.patient);
    const encounterId = computed(() => route.query.encounter);
    const facilityId = computed(() => route.query.facility);

    onMounted(() => {
      init();
    });

    watch(route, () => {
      init();
    }, { deep: true });

    function init () {
      const options = {
        patient: patientId.value,
        encounter: encounterId.value,
        facility: facilityId.value,
      };

      listMedicalRecords(options);
      listMedicalRecordsHistory({ facility: facilityId.value, patient: patientId.value });
      getPatientEncounters(patientId.value);
      getCurrentEncounter(encounterId.value);
    }

    return {
      encounters,
      encounter,
    };
  },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active,
.slide-move {
  transition: opacity 0.3s, transform 0.3s, margin 0.3s;
}

.slide-enter,
.slide-leave-to /* .slide-leave-active in <2.1.8 */,
.slide-move {
  opacity: 0;
  margin-left: -300px;
}
</style>
